<template>
  <div>
    <Header :headerIndex="7"></Header>
    <div class="container">
      <img class="g_top_banner" src="../assets/static/icon/icon_top_banner7.png" alt="" />
      <div class="detail_wrap">
        <div class="product_inner max_1440">
          <div class="flex-box">
            <router-link to="/productcenter" class="g_breadcrumb">Blog</router-link>
            <div class="g_breadcrumb mlr10">></div>
            <div class="g_breadcrumb">Blog Details</div>
          </div>
          <div class="title">{{ blog.title }}</div>
          <div class="date">{{ blog.createtime_text }}</div>
          <div style="color: black" v-html="blog.content"></div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import axios from "axios";
import api from "../api";
export default {
  components: {},
  data() {
    return {
      blog: [],
      blogid: "",
    };
  },
  created() {
    this.blogid = this.$route.query.blogid;
    console.log(this.blogid);
    this.getblog();
  },
  methods: {
    getblog() {
      var id = this.blogid ? this.blogid : "";
      api.doPost("/api/blog/blogdetail", { id: id }, (res) => {
        console.log(res)
        if(res.code == 200){
            this.blog = res.data
        }
      });
    },
  },
};
</script>

<style scoped>
.detail_wrap{
  padding: 30px 0;
}
.detail_wrap .title {
  padding: 30px 0 10px;
  font-size: 20px;
  line-height: 28px;
}
.detail_wrap .date {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 17px;
  text-align: center;
}
</style>
